* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  cursor: grab;
  filter: saturate(1.25);
}
